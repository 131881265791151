<template>
  <div class="position-relative overflow-hidden bg-white">
    <div class="d-flex align-items-center" :class="bg_color">
      <div class="mx-4 my-5 circle-badge-white flex-shrink-0 d-flex justify-content-center align-items-center">
        <i class="ri-custom" :class="[icon, icon_color]"></i>
      </div>
      <div>
        <h5 class="text-white fw-bold pr-3" :class="text_color">{{ $t(step) }}</h5>
        <h6 class="text-white pr-3" :class="text_color">{{ $t(title) }}</h6>
      </div>
    </div>
    <div v-if="circle_color" class="top-circle-white" :class="circle_color"></div>
    <div v-if="circle_color" class="top-circle" :class="circle_color"></div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types, vue/prop-name-casing
  props: ['step', 'title', 'icon', 'icon_color', 'bg_color', 'circle_color', 'text_color'],
}
</script>

<style></style>
