<template>
  <div>
    <div class="container" fluid="lg">
      <div class="row">
        <div v-for="(know_us, index) in know_uss" :id="know_us.title" :key="'mobile' + index" class="col-12 p-0">
          <div class="d-block d-lg-none">
            <step-mobile
              :step="know_us.step"
              :title="know_us.title"
              :bg_color="know_us.bg_color"
              :icon="know_us.icon"
              :circle_color="know_us.circle_color"
              :icon_color="know_us.icon_color"
              :text_color="know_us.text_color"
            >
            </step-mobile>
            <h6 class="m-4" v-html="$t(know_us.description)"></h6>
          </div>
          <div class="d-none d-lg-block">
            <div class="d-flex">
              <step-desktop-top
                :step="know_us.step"
                :bg_color="know_us.bg_color"
                :icon="know_us.icon"
                :circle_color="know_us.desktop_circle_color"
                :icon_color="know_us.icon_color"
                :text_color="know_us.text_color"
              >
              </step-desktop-top>
              <div>
                <h5 class="m-4">{{ $t(know_us.title) }}</h5>
                <h6 class="m-4" v-html="$t(know_us.description)"></h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import StepMobile from '@/components/StepMobile.vue'
import StepDesktopTop from '@/components/StepDesktopTop.vue'
import Footer from '@/components/Footer.vue'
export default {
  components: { StepMobile, StepDesktopTop, Footer },
  data() {
    return {
      know_uss: [
        {
          title: 'who_is_synpitarn',
          description: 'who_is_synpitarn_description',
          icon: 'ri-group-line',
          icon_color: 'text-dark',
          bg_color: 'bg-secondary-60',
          circle_color: null,
          desktop_circle_color: null,
          text_color: 'text-dark',
        },
        {
          title: 'our_loans',
          description: 'our_loans_description',
          icon: 'ri-profile-line',
          icon_color: 'text-dark',
          bg_color: 'bg-secondary-70',
          circle_color: 'bbg-white',
          desktop_circle_color: 'bg-secondary-60',
          text_color: 'text-dark',
        },
        {
          title: 'core_principles',
          description: 'core_principles_description',
          icon: 'ri-auction-line',
          icon_color: 'text-dark',
          bg_color: 'bg-secondary-80',
          circle_color: 'bbg-white',
          desktop_circle_color: 'bg-secondary-70',
          text_color: 'text-dark',
        },
        {
          title: 'do_you_qualified',
          description: 'do_you_qualified_description',
          icon: 'ri-award-line',
          icon_color: 'text-dark',
          bg_color: 'bg-secondary-90',
          circle_color: 'bg-white',
          desktop_circle_color: 'bg-secondary-80',
          text_color: 'text-dark',
        },
        // {
        //   title: "faqs",
        //   description: "faqs_description",
        //   icon: "ri-question-mark ",
        //   icon_color: "text-dark",
        //   bg_color: "bg-secondary",
        //   circle_color: "bg-white",
        //   desktop_circle_color: "bg-secondary-90",
        //   text_color: "text-dark",
        // },
      ],
    }
  },
  created() {
    const refName = this.$route.params.refName
    if (refName) {
      this.$nextTick(() => {
        this.scroll(refName)
      })
    }
  },
  methods: {
    scroll(id) {
      document.getElementById(id).scrollIntoView({
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style></style>
