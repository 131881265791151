<template>
  <div :class="bg_color">
    <div class="position-relative overflow-hidden">
      <div class="d-flex flex-column align-items-center p-5">
        <div class="circle-badge-white d-flex justify-content-center align-items-center">
          <i class="ri-custom" :class="[icon, icon_color]"></i>
        </div>
        <h5 class="text-white fw-bold my-4" :class="text_color">{{ $t(step) }}</h5>
      </div>
      <div v-if="circle_color" class="top-circle-white" :class="circle_color"></div>
      <div v-if="circle_color" class="top-circle" :class="circle_color"></div>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/require-prop-types, vue/prop-name-casing
  props: ['step', 'title', 'icon', 'icon_color', 'bg_color', 'circle_color', 'text_color'],
}
</script>

<style></style>
